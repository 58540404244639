export const defaultLocale = "fr";

export const localeOptions=[
    {
        id:'en',name:'English'
    },
    {
        id:'fr',name:'French'
    },
    {
        id:'nl',name:'Dutch'
    },
    {
        id:'de',name:'German'
    },
];
