import Auth from "@/auth/auth";
import login from "@/auth/login";

export default {
    path: '/auth',
    component: Auth,
    children: [
        {
            path: 'login',
            name: 'Login',
            component: login,
        },
    ]
};