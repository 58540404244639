import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import httpClient from "@api/httpClient";
import { getCurrentClub } from "@api/services/club/club.api";
import Bus from "@/utils/bus";

Vue.use(Vuex);

const urlAPI = process.env.VUE_APP_BASE_URL;

export default {
    namespaced: true,
    state: {
        status: '',
        token: JSON.parse(localStorage.getItem('token')) || '',
        user : JSON.parse(localStorage.getItem('user')) || null,
        club: JSON.parse(localStorage.getItem('current-club')) || '',
    },
    mutations: {
        authRequest(state){
            state.status = 'loading';
        },
        authSuccess(state, token){
            state.status = 'success';
            state.token = token;
        },
        authMe(state, user){
            state.user = user;
            state.status = 'success';
        },
        selectedClub(state, club){
            state.club = club;
        },
        authError(state){
            state.status = 'error';
        },
        logout(state){
            state.status = '';
            state.token = '';
        }
    },
    actions: {
        login ({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('authRequest');
                axios({
                    url: urlAPI+'admin_login_check',
                    data: user,
                    method: 'POST'
                })
                .then(resp => {
                    const refreshToken = resp.data.refresh_token;
                    const token = resp.data.token;
                    localStorage.setItem('refresh_token', JSON.stringify(refreshToken));
                    localStorage.setItem('token', JSON.stringify(token));
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                    httpClient.defaults.headers.Authorization = "Bearer "+ resp.data.token;
                    commit('authSuccess', token);
                    resolve(resp);
                })
                .catch(err => {
                    commit('authError');
                    reject(err);
                })
            })
        },
        getMe ({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: urlAPI + 'me',
                    method: 'GET'
                })
                    .then(resp => {
                        const user = resp.data;
                        localStorage.setItem('user', JSON.stringify(user));
                        commit('authMe', JSON.stringify(user));
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('authError', err);
                        reject(err)
                    })
            })
        },
        REFRESH_TOKEN: () => {
            return new Promise((resolve, reject) => {
                axios
                    .post(urlAPI + `jwt-token/refresh`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        refreshToken ({commit}) {
            return new Promise((resolve, reject) => {
                const refreshToken = localStorage.getItem("refresh_token")
                axios({
                    url: urlAPI+'jwt-token/refresh',
                    data: {'refresh_token': refreshToken},
                    method: 'POST'
                })
                .then(resp => {
                    const refreshToken = resp.data.refresh_token;
                    const token = resp.data.token;
                    localStorage.setItem('refresh_token', refreshToken);
                    localStorage.setItem('token', token);
                    axios.defaults.headers.common['Authorization'] = "Bearer "+token;
                    commit('authSuccess', token);
                    resolve(resp);
                })
                .catch(err => {
                    commit('authError');
                    reject(err);
                })
            })
        },
        logout ({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('token_refresh');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            })
        }
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        currentUser : (state) => {
            return state.user
        },
        currentClub : (state) => {
            if (state.club === null || state.club === undefined) {
                this.getSelectedClub().then(resp => {
                    return resp.data;
                });
            }
            return state.club
        },
    }
}
