import Vue from 'vue'
import App from './App.vue'
import { defaultLocale, localeOptions } from './constants/config'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/core/bread_crumbs'
import { store } from './store';
import VueI18n from 'vue-i18n';
import Vue2Filters from 'vue2-filters'
import PxCard  from './components/core/Pxcard.vue'
import VueObserveVisibility from 'vue-observe-visibility';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour'
import Notifications from 'vue-notification'
import { Vue2Dragula } from 'vue2-dragula'
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table'
import { VueMasonryPlugin } from 'vue-masonry'
import VueFeather from 'vue-feather';
import Chartist from "chartist";
import VueApexCharts from 'vue-apexcharts'
import carousel from 'vue-owl-carousel'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueIntro from 'vue-introjs';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import en from "./locales/en.json"
import fr from "./locales/fr.json"
import it from "./locales/it.json"
import de from "./locales/de.json"
import './assets/scss/app.scss';
import JQuery from 'jquery'
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css';
import Axios from 'axios'
import vClickOutside from 'v-click-outside';
import Vue2TouchEvents from 'vue2-touch-events';
import { MonthPicker } from 'vue-month-picker';
import { MonthPickerInput } from 'vue-month-picker';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import VueHover from 'vue-hover';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Dropdown from 'vue-js-dropdown';
import 'vuesax/dist/vuesax.css';

// import {VueCsvImportPlugin} from "../src/plugins/VueCsvImport";

Vue.prototype.$bus = new Vue();

window.$ = JQuery

// Vue.use(VueCsvImportPlugin);
Vue.use(VueMoment, { moment });
Vue.use(VueObserveVisibility);
Vue.use(VueTimepicker);
Vue.component('vue-timepicker', VueTimepicker);
Vue.use(MonthPicker);
Vue.use(MonthPickerInput);
Vue.use(Vue2TouchEvents);
Vue.use(vClickOutside);
Vue.prototype.$http = Axios;
Vue.use(VueFeather);
Vue.use(VueI18n);
Vue.use(Dropdown);

const messages = {fr: { ...require('@/locales/fr.json'), validations: {
            ...require('vee-validate/dist/locale/fr').messages,
            ...require('@/locales/validation/fr.json'),
        },}, en: en, it: it, de: de };
const locale =(localStorage.getItem('language') && localeOptions.filter(x=>x.id==localStorage.getItem('language')).length>0) ? localStorage.getItem('language') : defaultLocale
const i18n = new VueI18n({
    locale:locale,
    fallbackLocale:'fr',
    silentTranslationWarn: process.env.NODE_ENV === 'production',
    messages
})
Vue.use(Vuesax, {});
Vue.use(Toasted,{
    iconPack: 'fontawesome'
});
Vue.use(Vue2Dragula);
Vue.use(PerfectScrollbar);
Vue.use(Notifications)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2)
Vue.use(VueFormWizard)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(SmartTable)
Vue.use(require('vue-chartist'))
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.use(VueMasonryPlugin)
Vue.component('apexchart', VueApexCharts)
Vue.use(carousel)
Vue.use(CKEditor)
Vue.use(VueIntro)
Vue.prototype.$Chartist = Chartist;
Vue.config.productionTip = false
Vue.use(VueHover);
Vue.component(PxCard.name, PxCard);


import('@/plugins/components').then((obj) => {
    Vue.use(obj);
    Vue.config.silent = true;
    Vue.use(import('@/plugins/vee-validate'));
    Vue.use(import('@/plugins'));
    new Vue({
        router,
        store,
        i18n,
        mounted() {
            moment.locale(i18n.locale);
            if (localStorage.getItem('current-club')) {
                moment.tz(moment.utc().format(), JSON.parse(localStorage.getItem('current-club')).timezone);
            }
        },
        render: h => h(App)
    }).$mount('#app');
})
