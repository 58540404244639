<template>
  <!-- page-wrapper Start-->
  <div class="page-wrapper bg-image">
    <div class="container container-fluid p-0">
      <!-- login page start-->
      <div class="authentication-main m-0 h-100">
          <div class="col-md-12 h-100 align-center">
            <div class="authentication-box">
              <div class="card-body">
                <div class="cont text-center b-light">
                  <form class="theme-form">
                    <h4 class="mb-2">{{ $t("app.name") }}</h4>
                    <h6>{{$t("auth.login.box-description")}}</h6>
                    <div class="form-group">
                      <input
                          v-model="username"
                          class="form-control"
                          :placeholder="$t('auth.login.username')"
                          required=""
                          type="email"
                      />
                    </div>
                    <div class="form-group">
                      <input
                          v-model="password"
                          autocomplete=""
                          class="form-control"
                          :placeholder="$t('auth.login.password')"
                          required=""
                          type="password"
                      />
                    </div>
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label for="checkbox1">{{ $t("auth.login.remember-me")}}</label>
                    </div>
                    <div class="form-group form-row mt-3 mb-0">
                      <button
                          @click="signIn"
                          class="btn btn-primary btn-block"
                          type="button"
                      >
                        {{ $t("auth.login.connect") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- login page end-->
    </div>
    <b-modal
        :title="$t('auth.login.popup.login-error-title')"
        id="modal-login-error"
        centered
        no-close-on-backdrop
        hide-footer
        class="theme-modal"
    >
      <p>{{ $t('auth.login.popup.login-error-description')}}</p>
      <div class="text-center">
        <button
            class="btn btn-primary btn-sm txt-white"
            type="button"
            data-dismiss="modal"
            aria-label="Fermer"
            v-on:click="hidepopup"
        >{{ $t('auth.login.popup.close-btn')}}</button>
      </div>
    </b-modal>
    <b-modal size="sm" id="modal-loader-login" centered hide-footer hide-header hide-header-close class="theme-modal">
      <div class="loader-box">
        <h6 class="mr-1">{{ $t('auth.login.popup.login-connecting-description')}}</h6>
        <div class="loader-19"></div>
      </div>
    </b-modal>
  </div>
  <!-- latest jquery-->
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    hidepopup () {
      this.$bvModal.hide('modal-login-error');
    },
    signIn: function() {
      const username = this.username;
      const password = this.password;
      this.$bvModal.show('modal-loader-login');
      this.$store.dispatch('auth/login', { username, password })
          .then((resp) => {
            this.$store.dispatch('auth/getMe')
                .then(() => {
                  this.$store.dispatch('auth/getSelectedClub');
                  this.$bvModal.hide('modal-loader-login');
                  this.$router.push('/')
                })
            ;
          })
          .catch(err => {
            this.$bvModal.hide('modal-loader-login');
            this.$bvModal.show('modal-login-error');
          })
      ;
    },
  },
};
</script>
<style scoped>
.cont {
  overflow: hidden;
  position: relative;
  width: 600px;
  margin: 0 auto 0;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10pt rgba(0, 0, 0, 0.5);
}
.container {
  display: contents;
  position: relative;
  min-width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
  padding-top: 20pt;
}
.bg-image {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: url("../assets/images/doinsport/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.authentication-main {
  width: 100%;
  height: 100%;
}
.page-wrapper, .authentication-box {
  width: 100%;
  height: 100%;
}
</style>
