import currency from '@/data/currency.json'

const state = {
    currency: currency,
}

// getters
const getters = {
    getCurrency : (state) => { return state.currency; },
}

export default {
    namespaced: true,
    state,
    getters,
}