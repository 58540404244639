
export const hydrate = (model, data) => {
    for (const key of Object.keys(model)) {
        if (undefined !== data[key]) {
            model[key] = data[key];
        }
    }
};

export const fromIriReferenceToIdJSON = (ref, iriRef) => {
    return JSON.parse(iriRef).split(ref)[1];
};

export const fromIriReferenceToId= (ref, iriRef) => {
    return iriRef.split(ref)[1];
};

export const fromIdToIriReference = (ref, id) => {
    return ref.concat('/', id);
};

export const getShortWeekDayFromIndex = (index) => {
  switch (index) {
      case 0 : return 'general.actions.shortWeekDays.sunday';
      case 1 : return 'general.actions.shortWeekDays.monday';
      case 2 : return 'general.actions.shortWeekDays.tuesday';
      case 3 : return 'general.actions.shortWeekDays.wednesday';
      case 4 : return 'general.actions.shortWeekDays.thursday';
      case 5 : return 'general.actions.shortWeekDays.friday';
      case 6 : return 'general.actions.shortWeekDays.saturday';
  }
};

export const unAssign = (target, source) => {
    Object.keys(source).forEach(key => {
        delete target[key];
    });
};

export const getIndexFromWeekDay = (weekDay) => {
  switch (weekDay) {
      case 'general.actions.week.sunday' : return 0;
      case 'general.actions.week.monday' : return 1;
      case 'general.actions.week.tuesday' : return 2;
      case 'general.actions.week.wednesday' : return 3;
      case 'general.actions.week.thursdays' : return 4;
      case 'general.actions.week.friday' : return 5;
      case 'general.actions.week.saturday' : return 6;
  }
};

export const getWeekDayFromIndex = (weekDay) => {
  switch (parseInt(weekDay)) {
      case 0 : return 'general.actions.week.sunday';
      case 1 : return 'general.actions.week.monday';
      case 2 : return 'general.actions.week.tuesday';
      case 3 : return 'general.actions.week.wednesday';
      case 4 : return 'general.actions.week.thursdays';
      case 5 : return 'general.actions.week.friday';
      case 6 : return 'general.actions.week.saturday';
  }
};

export const randomString = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);


