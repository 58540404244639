const path = 'clubs';

export default [
  {
    path: path,
    name: 'clubs',
    component: () => import('@views/clubs/Index'),
    meta: {
      title: 'admin.clubs.list_clubs',
    }
  },
  {
    path: path + '/:id' + '/nextore',
    name: 'nextore_form',
    component: () => import('@views/nextore/Index'),
  },
  {
    path: path + '/new',
    name: 'clubs_new',
    component: () => import('@views/clubs/new/Index')
  },
  {
    path: path + '/update/:id',
    name: 'clubs_update',
    component: () => import('@views/clubs/new/Index')
  },
  {
    path: path + '/detail/:id',
    name: 'clubs_detail',
    component: () => import('@views/clubs/detail/Index')
  },
  {
    path: path + '/detail/:id/user/:idUser?',
    name: 'user',
    component: () => import('@views/clubs/detail/new-admin/Index')
  },
  {
    path: path + '/duplicate/:id',
    name: 'clubs_duplicate',
    component: () => import('@views/clubs/duplicate/Index')
  }
]
