import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto';
import menu from './modules/menu';
import auth from './modules/auth';
import language from './modules/language';
import layout from './modules/layout';
import currency from './modules/currency';

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    modules: {
        auth,
        currency,
        menu,
        language,
        layout
    },
    strict: false
});

