<template>
  <div :key="'sidebar-' + componentKey">
    <!-- Page Sidebar Start-->
    <div class="iconcolor-sidebar">
      <div class="sidebar custom-scrollbar">
        <ul
        class="sidebar-menu"
      >
      <li
          class="left-arrow"
          :class="{'d-none': layout.settings.layout_type=='rtl'? hideLeftArrowRTL: hideLeftArrow}"
          @click="(layout.settings.sidebar.type === 'horizontal_sidebar' && layout.settings.layout_type==='rtl') ? scrollToLeftRTL() : scrollToLeft()"
        >
          <i class="fa fa-angle-left"></i>
        </li>
          <li class="logo-wrap">
            <div
                class="sidebar-header pointer"
                @click="goToHome()"
            >
              <img width="30" height="30" src="../../assets/images/logo/doinsport-logo.svg" alt/>
            </div>
          </li>
          <li
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :class="isActiveMenu(menuItem) ? 'active-li': ''"
            @mouseover="menuItem.size= 30"
            @mouseleave="menuItem.size = 27"
            @click="show(menuItem)"
          >
            <router-link
              :to="menuItem.path"
              class="sidebar-header"
              v-if="menuItem.type == 'link'"
              router-link-exact-active
            >
              <img :width="18" :src="(!isActiveMenu(menuItem) && menuItem.size === 27 ) ? require(`@/assets/icons/sidebar/default/${menuItem.icon}.svg`) : require(`@/assets/icons/sidebar/hover/${menuItem.icon}.svg`) " />
              <i class="fa fa-angle-right pull-right" v-if="menuItem.children"></i>
            </router-link>
          </li>
          <li
          class="right-arrow"
          :class="{'d-none': layout.settings.layout_type=='rtl'? hideRightArrowRTL : hideRightArrow }"
          @click="(layout.settings.sidebar.type == 'horizontal_sidebar' && layout.settings.layout_type=='rtl') ? scrollToRightRTL() : scrollToRight()"
        >
          <i class="fa fa-angle-right"></i>
        </li>
        </ul>
      </div>
    </div>
    <!-- Page Sidebar Ends-->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {
      width: 0,
      componentKey: 0,
      height: 0,
      equalPath: '/planning',
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0
    };
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      sidebar: state => state.layout.sidebarType
    })
  },
  created() {
    window.addEventListener("resize", this.handleResize);

    this.handleResize();

    if (this.width < 991) {
      this.layout.settings.sidebar.type = "default";
    }

    const val  = this.sidebar
      if (val == 'default') {
				this.layout.settings.sidebar.type = 'default';
				this.layout.settings.sidebar.body_type = 'default';
			} else if (val == 'compact') {
				this.layout.settings.sidebar.type = 'compact-wrapper';
				this.layout.settings.sidebar.body_type = 'sidebar-icon';
			} else if (val == 'compact-icon') {
				this.layout.settings.sidebar.type = 'compact-page';
				this.layout.settings.sidebar.body_type = 'sidebar-default';
			} else if (val == 'horizontal')  {
				this.layout.settings.sidebar.type = 'horizontal_sidebar';
        this.layout.settings.sidebar.body_type = '';
      }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$bus.$on('refresh-sidebar', () => {
      this.componentKey ++;
    });
    this.setFilters();
  },
  methods: {
    goToHome () {
      if (this.$router.currentRoute.name !== 'clubs') {
        this.$router.push('/clubs');
        this.componentKey ++;
      }
    },
    setFilters () {
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
    },
    isActiveMenu(menuItem) {
      return this.$router.currentRoute.path.includes(menuItem.path);
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
    },
    getMainHeaderLeftStyle() {
      return 'height:100px';
    },
    handleResize() {
      this.width = window.innerWidth - 310;
    },
    scrollToRightRTL() {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      }
      // else
      else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL() {
      // If Margin is reach between screen resolution
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      }
      //Else
      else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    show(menuItem) {
    },
    scrollToLeft() {
      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      }
      //Else
      else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight() {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      }
      // else
      else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    }
  }
};
</script>
<style scoped>
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 5px;
  padding-right: 5px;
}

.page-wrapper .page-body-wrapper .sidebar {
  height: 100vh;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/deep/ .page-wrapper .page-body-wrapper .page-sidebar.dark-sidebar {
  background-color: #ffffff;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-header > span {
  display: block !important;
  font-size: 25px !important;
  margin-top: 2px;
  letter-spacing: 0;
}
.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
  z-index: 9999;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 10px 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu {
  padding: 0;
}
.logo-wrap {
  margin-bottom: 120px;
}

.active-li {
  padding: 0;
  border-right: 3px solid #FF0101;
}

.page-sidebar .sidebar-menu > li > a:hover {
  #background-color: rgba(0,0,0,0.01)!important;
}
</style>
