import en from "@/locales/en.json"
import fr from "@/locales/fr.json"
import it from "@/locales/it.json"
import de from "@/locales/de.json"

export default {
    en,
    fr,
    it,
    de
};