<template>
  <div>
    <div id="canvas-bookmark" class="page-wrapper" :class="layout.settings.sidebar.type">
      <div class="page-body-wrapper" :class="layout.settings.sidebar.body_type">
        <div class="page-sidebar" :class="[{ open : sidebar_toggle_var }, transparentbg ? '' : layout.settings.sidebar_backround ]" :sidebar-layout="layout.settings.sidebar_setting">
          <Header @clicked="sidebar_toggle" @rightclicked="rightsidebar_toggle" @mobileclicked="mobiletoggle_toggle" />
          <Sidebar />
        </div>
        <div class="page-body">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from './header'
import Sidebar from './sidebar'
import RightSidebar from './right_sidebar'

export default {
  name: 'mainpage',
  data(){
    return{
      rightsidebar_toggle_var:false,
      mobileheader_toggle_var: false,
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized:false
    }
  },
  // props:['sidebar_toggle_var'],
  components:{
    Header,
    Sidebar,
    RightSidebar,
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      transparentbg: state => state.layout.transparentbg
    })
  },
  created(){
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch('layout/set')
  },
  watch:{
    '$route' (){
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', items)
        if (!items.children) return false
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch('menu/setActiveRoute', subSubItems)
          })
        })
      })
    },
    sidebar_toggle_var: function (){
      this.resized = (this.width <= 991) ? !this.sidebar_toggle_var : this.sidebar_toggle_var
    }
  },
  methods:{
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value
    },
    rightsidebar_toggle(value) {
      this.rightsidebar_toggle_var = value
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value
    },
    handleResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 991px) {
  .page-wrapper .page-body-wrapper .page-sidebar {
    top: 0;
    height: 100%;
  }
}

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 85px);
  margin-top: 85px;
  position: relative;
  background-color: #F8FAFA;
  padding: 10pt;
  border-radius: 15pt 0 0 0;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar {
  opacity: 1;
  z-index: 1;
  background-color: white;
  position: fixed;
  height: auto;
  width: 63px;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 991px) {
  .page-body-wrapper .page-body, .page-body-wrapper footer {
    margin-left: 75px !important;
  }
}
.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar ~ .page-body {
  margin-left: 60px;
  margin-top: 63px;
  width: 100%;
}
body.light-only .page-wrapper .page-body-wrapper .page-body {
  background: #F8FAFA 0% 0% no-repeat padding-box;
}
.page-sidebar, .page-body-wrapper {
  #background-color: #1a1819!important;
}
</style>
