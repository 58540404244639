const path = 'news';

export default [
  {
    path: path,
    name: 'news',
    component: () => import('@views/news/Index'),
  },
  {
    path: path + '/form',
    name: 'news_form',
    component: () => import('@views/news/create-or-update/Index'),
  },
  {
    path: path + '/form/:id',
    name: 'update_form',
    component: () => import('@views/news/create-or-update/Index'),
  }
]
