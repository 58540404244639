<template>
  <div class="zoom-90" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Bus from "@/utils/bus";

export default {
  name: "app",
  beforeCreate() {
    this.$store.commit('layout/setWidth', window.innerWidth);
  },
  beforeDestroy() {
    try {
    Bus.$off('on:400-errors-handled');
    } catch (err) {
      console.error(err);
    }
  },
  mounted () {
    Bus.$on('on:400-errors-handled', (data) => {
      for (const violation of data.violations) {
        this.$vs.notify({ time: 10000, title: this.$t('general.actions.notification') ,text: violation.message, color: 'red-light',position: 'top-right'});
      }
    })
    window.addEventListener('resize', () => {
      this.$store.commit('layout/setWidth', window.innerWidth);
    });
  },
};
</script>
<style>
.zoom-90{
  zoom: 0.9;
}
</style>
