import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
         Authorization: "Bearer " + JSON.parse(localStorage.getItem('token'))
    }
});

export default httpClient;