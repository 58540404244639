<template>
  <!-- Right sidebar Start-->
  <div class="right-sidebar" :class="{ show:rightsidebar_toggle_var }" id="right_side_bar">
    <div>
      <div class="container p-0">
        <div class="modal-header p-l-20 p-r-20">
          <div class="col-sm-8 p-0">
            <h6 class="modal-title font-weight-bold">FRIEND LIST</h6>
          </div>
          <div class="col-sm-4 text-right p-0">
            <feather class="mr-2" type="settings"></feather>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Right sidebar Ends-->
</template>
<script>
export default {
  name: "rightsidebar",
  props: ["rightsidebar_toggle_var", "msg"],
  data() {
    return {
      search: ""
    };
  },
  methods: {
    getImgUrl(path) {
            return require('@/assets/images/'+path)
        },
    setSerchUsers: function() {
      if (this.search != "")
        this.$store.dispatch("chat/setSerchUsers", this.search);
    }
  }
};
</script>
