<template>
  <div
    :key="'header' + componentKey"
    :class="{ open:toggle_sidebar_var }"
    class="page-main-header"
  >
    <div
      class="main-header-right row"
    >
      <div
        class="d-flex align-items-center align-content-center mb-0 pr-0 col-md-12 justify-content-end"
      >
        <ul class="nav-menus display-content" :class="{ open: mobiletoggle2}">
          <li>
              <span
                class="ml-2 mt-1 mr-3 icons-size-disconnect fa fa-power-off pointer text-danger"
                @click="logout()"
              >
              </span>
          </li>
          <li>
            <div class="d-flex align-items-center user-name">
                <span class="user-label media-body">
                  <span
                    class="mr-2 mt-2"
                  >
                    {{ firstName }} {{ lastName }}
                  </span>
                  <span class="d-block">
                    {{ userName }}
                  </span>
                </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      firstName: '',
      lastName: '',
      userName: '',
      toggle_sidebar_var:false,
      mobiletoggle2: false,
      componentKey: 1,
    }
  },
  mounted () {
    const user = JSON.parse(localStorage.getItem('user'));
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.userName = user.username;
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.replace('/auth/login');
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}

</script>
<style lang="scss" scoped>
@import "@lazy/menu/_header-menu.scss";

.user-label {
  font: normal normal 700 14px Avenir;
  letter-spacing: -0.4px;
  color: #4D4F5C;
}
</style>
